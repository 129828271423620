.CompanyNotes {
  max-width: 50%;
  flex: 1;
  margin-right: 20px;
}

.custom-button:hover {
  background-color: pink !important;
}

.calendar-events-add {
  font-size: 18px;
  position: absolute;
  top: 0px;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.25);
  }
}

.calendar-events {
  font-size: small;

  .events {
    padding-inline: 5px;
    display: flex;
    justify-content: space-between;
    transition: transform 0.1s ease-in-out;

    &:hover {
      background-color: #2e426486;
      transform: scale(1.02);
      border-radius: 3px;
    }
  }
}

.calendar-event-modal {
  .calendar-event-input,
  .calendar-event-range-picker,
  .calendar-event-time-picker,
  .calendar-event-checkbox {
    margin-bottom: 5px;
  }

  .calendar-event-time-picker {
    margin-right: 5px;
  }
}

@media (max-width: 999px) {
  .contentContainer {
    .CompanyNotes {
      max-width: 100%;
    }

    .management-calendar {
      width: 200%;
    }

    width: 300%;
    height: 150%;
  }
}
.management-calendar .ant-picker-calendar-date-content {
  height: 150px !important;
}
