.MonthResetButton {
  img {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1); 
    transition: all 0.4s;
  }
  img:hover {
    transform: rotateZ(-90deg);
  }
}