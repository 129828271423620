@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.deviceControlContainer {
  padding: 20px;
  border-radius: 10px;

  .sectionTitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .deviceColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    @include sm {
      flex-direction: column;
      gap: 15px;
    }
  }

  .deviceColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border: 2px solid $soft-bg;
    border-radius: 10px;
    padding: 15px;
    min-width: 200px;
    text-align: center;
    height: 200px;

    h4 {
      margin-bottom: 10px;
    }

    .statusRow {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 10px;

      span {
        font-size: 14px;
      }
    }

    button {
      margin-top: auto;
      padding: 10px 20px;
      background-color: darken($dark-color, 10%);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: lighten($dark-color, 3%);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
      &:disabled {
        background-color: $soft-bg;
        color: gray;
        cursor: not-allowed;
      }
    }

    .led-indicator {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      &-on {
        background-color: green;
        box-shadow: 0 0 10px green;
      }

      &-off {
        background-color: red;
        box-shadow: 0 0 10px red;
      }
    }
  }
}
