.custom-row-style .ant-table-row {
  height: 65px; /* upravena výška řádku, aby když se zmáčkl select, nezměnila se výška */
}
.datePickers {
  display: flex;
  flex-direction: row;
}
.ant-table-tbody .row-danger {
  background-color: #ffcccc !important;
}
.ant-table-tbody > tr.row-danger:hover > td {
  background-color: #ffaaaa !important;
}
.ant-table-tbody > tr.row-danger:hover > td.ant-table-column-sort {
  background-color: #ffaaaa !important;
}
