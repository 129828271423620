// pieChartBox
@import "../../styles/responsive.scss";

.pieChartBox {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    @include xxl {
      font-size: 24px;
    }
  }

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
    justify-content: center;

    .option {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      width: calc(33.33% - 10px);
      text-align: center;
      animation: fadeIn 1s ease;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        height: 100%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
  .selectors {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;

    .ant-select {
      width: 120px;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
