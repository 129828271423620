.ant-tabs-card .ant-tabs-tab {
  background: #bfc0c2 !important; // Set the background color of the tabs
  border: 1px solid #d9d9d9 !important; // Border color to match the card style
}

.ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important; // Set the active tab background color
  border: 1px solid #d9d9d9 !important; // Border color to match the card style
}

.ant-card {
  background: #f0f2f5 !important; // Set the background color of the cards
  border: 1px solid #d9d9d9 !important; // Border color to match the tab style
}

.ant-tabs-card .ant-tabs-content-holder {
  background: #ffffff !important; // Set the background color of the content holder
  border: 1px solid #d9d9d9 !important; // Border color to match the card style
}
.ant-tabs-tab-btn {
  color: rgb(0, 0, 0) !important;
}

.TabsCard {
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 1%;
  width: 100%;
  border: #969799;
  border-radius: 10px solid #fff;
}

// EDIT MODAL
.editModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .editModalLeft {
    flex: 1;
    margin-right: 10px;
  }

  .editModalRight {
    flex: 1;

    .ingredient-item {
      margin-bottom: 10px;
      align-items: center;
      label {
        width: 70px;
      }
    }
    .ingredient-item-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
}
.hoverRowHighlight:hover {
  font-weight: 600;
  cursor: pointer;
}

.makeSelectToText {
  .ant-select-selector {
    background-color: white !important;
    color: black !important;
    border: none !important;
    cursor: default !important;
  }
}
