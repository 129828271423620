.invoice-template {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;

  .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;

    h1 {
      font-size: 28px;
      color: #444;
      margin: 0;
    }

    .invoice-info {
      text-align: right;

      p {
        margin: 0 0 5px 0;
      }
    }
  }

  .invoice-addresses {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .supplier-info {
      width: 50%;
      text-align: left;

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        margin: 5px 0;
      }
    }
  }

  .invoice-details {
    margin-bottom: 10px;

    width: 50%;
    text-align: right;
    .details-column {
      width: 100%;
      table {
        width: 100%;
        border-collapse: collapse;
        margin-left: auto;
        td {
          padding-bottom: 5px;

          &:first-child {
            width: 40%;
          }
        }
      }
    }
  }

  .invoice-items {
    margin-bottom: 30px;

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        font-size: 14px;

        padding: 5px;
        text-align: left;
        border: 1px solid #ddd;
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
    }
  }

  .invoice-summary {
    margin-bottom: 30px;
    width: 48%;
    table {
      width: 100%;
      border-collapse: collapse;

      td {
        padding: 8px;
        border-bottom: 1px solid #eee;

        &:first-child {
          font-weight: bold;
          width: 70%;
        }

        &:last-child {
          text-align: right;
        }
      }

      .total {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .vat-summary {
    margin-bottom: 30px;
    width: 48%;
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 8px;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
    }
  }
  .summary-columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .vat-summary,
    .invoice-summary {
      width: 48%;
    }

    .invoice-summary {
      // Základní styl pro plnou šířku, pokud není vat-summary
      width: 100%;
      margin-left: auto; // Zarovná na pravou stranu
    }

    // Pokud existuje .vat-summary, upravíme šířku .invoice-summary
    .vat-summary + .invoice-summary {
      width: 48%;
      margin-left: 0;
    }
  }

  .invoice-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding-top: 20px;

    .payment-details,
    .additional-info {
      width: 48%;
    }

    h3 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }

    p {
      margin: 5px 0;
    }
  }
}
