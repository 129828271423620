@import "../../styles/responsive.scss";

.bigChartBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .custom-select__menu {
    .custom-select__option:hover {
      background-color: #2a3447; /* Změňte na požadovanou barvu */
      color: white; /* Barva textu položek */
    }
    .custom-select__option {
      background-color: #384256; /* Změňte na požadovanou barvu */
      color: white; /* Barva textu položek */
    }
  }
  h1 {
    @include xxl {
      font-size: 24px;
    }
  }
  .select {
    color: white;
  }
  .chart {
    width: 100%;
    height: 300px;
  }
  .select-and-reset {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
.custom-tooltip {
  background-color: #1c2431;
  padding: 10px;
  border-radius: 5px;
}
