// BG
$main-bg: #2a3447;
$soft-bg: #384256;
$dark-bg: #222b3c;
//TEXT
$main-color: white;
$soft-color: #ddd;
$dark-color: #2a3447;

/* Styl pro načítací obrazovku */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Výška bude 100% viewportu */

  .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #474bff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #474bff);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
}
