@import "./variables.scss";
@import "./responsive.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
}
body {
  background-color: $main-bg;
}
a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: "Inter", sans-serif;
  background-color: $main-bg;
  color: $main-color;
}

.container {
  display: flex;
}

.menuContainer {
  width: 240px;
  padding: 5px 20px;
  border-right: 2px solid $soft-bg;

  @include sm {
    padding: 5px 15px;
  }
  @include lg {
    width: max-content;
  }
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}
@media (max-width: 600px) {
  .contentContainer {
    padding: 0;
  }
}
.cl-footer {
  display: none;
}
.cl-internal-b3fm6y {
  display: none;
}

.App {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #222b3c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-pagination
  .ant-pagination-item
  a {
  display: block;
  padding: 0 6px;
  color: white;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-pagination
  .ant-pagination-item-active
  a {
  color: #2a3447;
}
