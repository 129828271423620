.actualChartbox {
  .headerWithSelectors {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectors {
      display: flex;
      gap: 10px;
    }
  }
}
