.dailySalesH1 {
  margin-bottom: 10px;
}
.datePickers {
  margin-bottom: 10px;
  justify-content: space-between;

  .rangepicker {
    width: 250px;
  }
}
