.footer{
  padding: 30px;
  text-align: center;
  span{
    user-select: none; 
    &:first-child{
      font-weight: bold;
    }

    &:last-child{
      font-size: 14px;
    }
  }
}