.FAQContainer {
  padding: 20px;
  background-color: #222b3c;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  user-select: none;
  gap: 20px;
  .headerAndTabsContainer {
    background-color: #222b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;

    .header {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    h2 {
      color: #ddd;
      font-weight: bold;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }

    .reset-button {
      background-color: transparent;
      cursor: pointer;
      transition: transform 0.3s ease;

      align-items: center;
      text-align: center;
      justify-content: center;
      margin-bottom: 2px;
      width: 75px;
      height: 75px;
    }
  }

  h3 {
    color: #ddd;
    padding: 5px;
    margin-bottom: 10px;
  }

  :where(.css-dev-only-do-not-override-1adbn6x).ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #2a3447;
    pointer-events: none;
  }

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    justify-content: center;

    min-width: 120px;
  }
  .ant-tabs-tab-btn {
    color: white !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    color: white;

    transition: color 300ms ease, transform 300ms ease;
    transform-origin: left center;
    &:hover {
      transform-origin: left center;
      color: rgb(79, 145, 108);

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: green;
        border-color: green;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: green !important;
      border-color: green !important;
    }
  }

  .images-row {
    display: flex;
    gap: 10px;
    margin-left: 1%;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
  }
}

.onlyFaqContainer {
  display: flex;
  flex-direction: column;
  background-color: #222b3c;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;

  h4 {
    font-size: large;
    padding-bottom: 15px;
  }

  .overall {
    display: flex;
    flex-direction: column;
    background-color: #222b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin-bottom: 15px;
  }

  .faqSegments {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: start;
    background-color: #222b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;

    .faqWolt,
    .faqBolt,
    .faqNesnezeno {
      display: flex;

      margin-bottom: 20px;
      flex-direction: column;
      background-color: #222b3c;
      min-width: 300px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
      padding: 20px;
    }
  }
}
