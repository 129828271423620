@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.containerUpper {
  border-radius: 10px;
  // border: 2px solid $soft-bg;
}
.calculatorH1 {
  margin: 10px 0px 0px 20px;
}

.containerWrap {
  display: flex;
  flex-direction: row;

  .dailyStatus {
    margin: 15px 15px;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid $soft-bg;

    .date,
    .yesterdayStatus,
    .todayStatus {
      margin-bottom: 10px;
      margin-right: 20px;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      font-size: 20px;

      .bold {
        font-weight: bold;
      }
    }

    .tableContainer {
      display: flex;
      justify-content: space-between;
    }

    h3 {
      margin-bottom: 10px;
    }

    h4,
    span {
      display: block;
    }
  }
  .inputValue {
    flex: 1;
    margin: 15px 0px;
    border-radius: 10px;
    border: 2px solid $soft-bg;
    padding: 20px 20px 0px 20px;
    max-width: 700px;
    h3 {
      margin-bottom: 10px;
    }

    h4,
    span {
      display: block;
    }

    .weather,
    .cashier,
    .dailysale,
    .card,
    .wolt,
    .foodora,
    .bolt,
    .notes,
    .manko,
    .cash,
    .cashInPOS,
    .date,
    .nesnezeno {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input {
        display: flex;
        align-items: center;
        min-width: 105px;
        position: relative;

        .noteInput {
          min-width: 350px;
        }
        input {
          text-align: right;
          max-width: 80px;
          padding: 5px;
        }
        input::placeholder {
          text-align: center;
        }
        input:hover {
          border-color: green;
        }
        input:active {
          border-color: blue;
        }
        span {
          margin-left: 4px;
        }
      }
      .left {
        min-width: 165px;
      }
      .midSpan {
        min-width: 100px;
        text-align: right;
      }

      span {
        text-align: left;

        &:first-child {
          font-weight: bold;
        }
      }

      .spanCash {
        width: 105px;
        text-align: right;
      }
      .spanCashInPos {
        min-width: 105px;
        text-align: right;
      }
      .spanManko {
        user-select: none;

        &:hover {
          transform: scale(1.2);
          transition: transform 0.3s ease;
        }
      }
    }
  }
}

.moneyCalculator {
  margin-top: 20px;
  h1 {
    margin-bottom: 15px;
  }
}

.containerLower {
  border-radius: 10px;

  .calculatorH1 {
    margin: 10px 0px 0px 20px;
  }
  .leftContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: fit-content;

    .calculatorCard {
      flex: 3;
      min-width: 528px;
      max-width: 600px;
    }

    .table {
      flex-grow: 2;
      text-align: center;
      align-items: center;
      padding: 15px;
      margin: 0px 0px 15px 15px;

      .faceValue {
        width: 25%;
        height: 40px;
      }
      .pieces {
        width: 40%;
        height: 40px;
      }
      .total {
        width: 25%;
        height: 40px;
      }
      .deleteAllRows {
        width: 10%;
        height: 40px;
        span {
          user-select: none;
          font-size: 16px;
          transition: transform 0.3s ease;
          cursor: pointer;
        }
      }

      .resetColumn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          user-select: none;
          font-size: 16px;
          transition: transform 0.3s ease;
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
          }
        }
      }

      .plusMinus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
          user-select: none;
          font-size: 25px;
          border-radius: 8px;
          margin: 5px;
          transition: transform 0.3s ease;
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .tableResult {
      min-width: 250px;
      max-width: 250px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0px 0px 0px 15px;

      .totalKc {
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 100%;
        justify-content: space-between;
        .left {
          min-width: fit-content;
          display: flex;
          flex-direction: column;
        }

        .right {
          min-width: fit-content;
          display: flex;
          flex-direction: column;
        }
      }

      .totalEur {
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 100%;
        justify-content: space-between;
        .left {
          min-width: fit-content;
          display: flex;
          flex-direction: column;
        }

        .right {
          min-width: fit-content;
          display: flex;
          flex-direction: column;
        }
      }
      .kurzEUR {
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 100%;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            margin-bottom: 0;
          }
        }
        .right {
          padding-left: 5%;
          display: flex;
          flex-direction: column;
        }

        .inputKurzEuro {
          line-height: "30px";
          width: "45px";
          text-align: "center";
        }
      }
      .celkovaSuma {
        border-top: 1px solid rgba(128, 128, 128, 0.5);
        margin-top: 10px;
        padding: 20px 10px 10px 10px;
      }
    }
  }
}

.cashInOut {
  margin-top: 10px;
  margin-bottom: 10px;

  .inputs {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    input::placeholder {
      text-align: center;
    }
    .ant-select-selector {
      min-width: 100px;
    }
    .inputNumber {
      width: 200px;
      text-align: right;
    }
    .inputDescription {
      text-align: right;
    }
  }
}

.saveCalculator {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1650px) {
  .containerWrap {
    flex-wrap: wrap;
  }
  .saveButtonCalculator {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.cardDiff {
  position: absolute;
  top: 5px;
  right: 110px;
  color: rgb(211, 211, 211);
}
