.invoice-table {
  h1 {
    margin-bottom: 8px;
  }

  p {
    color: #666;
    margin-bottom: 16px;
  }

  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    font-weight: 600;
  }

  .email {
    color: #1890ff;
  }

  .status {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;

    &.paid {
      background-color: #f6ffed;
      color: #52c41a;
    }

    &.unpaid {
      background-color: #fff2e8;
      color: #fa541c;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .action-icon {
    font-size: 18px;
    margin-right: 8px;
    color: #1890ff;
    cursor: pointer;
  }

  .ant-pagination {
    margin-top: 16px;
  }

  .ant-pagination-options {
    margin-left: 16px;
  }

  .ant-space {
    margin-bottom: 16px;
  }

  .ant-picker {
    margin-left: 8px;
  }
}

.summary-row {
  font-weight: bold;
  background-color: #f0f0f0; // světle šedé pozadí pro lepší vizuální oddělení
}
.invoice-footer {
  text-align: left;
  padding-left: 0px;
  font-weight: bold;
  color: #000000;
  font-size: 14px;
}
