.message {
    padding: 5px;
    margin-bottom: 5px;
  }
  
  .message.user {
   color: #dcdcdc;
    text-align: right;
  }
  
  .message.bot {
    color: #a9d18e;
  }