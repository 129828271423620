.receipt-template {
  margin: 0 auto;
  padding: 20px;
  font-family: "Courier New", Courier, monospace; // Monospace font for a receipt-like feel
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: fit-content; // Adjusts width to fit content
  max-width: 100%; // Ensures it doesn't overflow the container
  overflow-x: auto; // Adds horizontal scroll if content overflows

  .receipt-header {
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 2px dashed #000;
    padding-bottom: 10px;

    h1 {
      font-size: 18px;
      margin: 0;
      color: #000;
    }

    p {
      margin: 5px 0;
      font-size: 12px;
    }
  }

  .receipt-items {
    margin-bottom: 15px;

    table {
      width: 100%;
      border-collapse: collapse;
      word-wrap: break-word;

      th,
      td {
        font-size: 12px;
        padding: 4px;
        text-align: left;
      }

      th {
        border-bottom: 1px dashed #000;
        font-weight: normal;
        text-transform: uppercase;
      }

      td {
        border-bottom: 1px dashed #ccc;
      }
    }
  }

  .receipt-summary {
    margin-bottom: 15px;

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        padding: 4px;
        font-size: 12px;

        &:first-child {
          font-weight: bold;
        }

        &:last-child {
          text-align: right;
        }
      }

      .total-amount {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .receipt-footer {
    text-align: center;
    border-top: 2px dashed #000;
    padding-top: 10px;

    p {
      margin: 5px 0;
      font-size: 12px;
    }
  }
}
