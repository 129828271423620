@import "../../styles/responsive.scss";

.navbar {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    flex: 1;
    justify-content: left;
    font-weight: bold;
    margin-left: 25px;
    gap: 10px;
    user-select: none;
    font-size: 45px;
    a {
      cursor: pointer;
    }
    @include sm {
      display: none;
    }
  }

  .icons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: right;

    .iconLinks {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 10px 25px 0px 0px;
      img {
        transition: transform 0.2s ease;
        &:hover {
          transform: scale(1.3);
        }
      }
      @include sm {
        gap: 5px;
        padding: 0px;
      }
      .restart-button {
        background-color: transparent;
        cursor: pointer;
        transition: transform 0.3s ease;

        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 2px;
        width: 50px;
        height: 50px;
        &:hover {
          transform: scale(2);
        }
      }
    }

    .restartButton {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  .user {
    font-family: "Inter", sans-serif;

    display: flex;
    align-items: center;
    padding: 15px;
    flex-direction: column;
    @include sm {
      gap: 5px;
      padding: 0px;
    }
    .username {
      align-items: center;
    }
  }

  .role-switcher {
    cursor: pointer;
    padding: 0 20px;
    color: white;
    cursor: pointer;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: -15px;
  }

  .logout-button {
    background: none;
    border: none;
    padding: 15px;
  }
  .logout-icon {
    font-size: 18px;
    cursor: pointer;
    color: white;
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.3);
    }
  }

  .sm-none {
    @include sm {
      display: none;
    }
  }
}

.cl-userButtonPopoverCard {
  font-family: "Inter", sans-serif;
}

.indicator-connected {
  font-size: 20px;
  width: 30px;
  text-align: center;
}

.indicator-disconnected {
  font-size: 20px;
  width: 30px;
  text-align: center;
}
