@import "../../styles/responsive.scss";

.bigChartBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    @include xxl {
      font-size: 24px;
    }
  }
  .chart {
    width: 100%;
    height: 300px;
  }
}
.custom-tooltip {
  background-color: #384256;
  padding: 10px;
  border-radius: 5px;
  .firstPayload {
    color: #82ca9d; // 2023
  }
  .secondPayload {
    color: #ffc658; // 2022
  }
  .thirdPayload {
    color: #8884d8; // 2021
  }
  .fourthPayload {
    color: #70a1ff; // 2024
  }
  .fifthPayload {
    color: #ff6b81; // 2025
  }
}
