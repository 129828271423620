@import "../../styles/responsive.scss";

.bigChartBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chartBox {
  display: flex;
  height: 100%;

  @include sm {
    flex-direction: column;
  }

  .boxInfo {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include sm {
      gap: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      @include xxl {
        font-size: 14px;
      }
    }

    h1 {
      font-size: 20px;
    }
  }

  .chartInfo {
    flex: 2;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    .chart {
      align-items: right;
      width: 50%;
      height: 100%;
    }

    .texts {
      display: flex;
      flex-direction: column;
      text-align: right;

      .percentage {
        font-weight: bold;
        font-size: 20px;

        @include xxl {
          font-size: 16px;
        }
      }

      .duration {
        font-size: 14px;
      }
    }
  }
}
