.ShiftContainer {
  overflow: hidden;
  height: 100%;
  opacity: 1;

  &.hidden {
    height: 0;
    opacity: 0;
  }
}

.calendar-events-add {
  font-size: 18px;
  position: absolute;
  top: 0px;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
}

.calendar-events-add:hover {
  transform: scale(1.25);
}

.calendar-events {
  font-size: small;

  .events {
    padding-inline: 5px;
    display: flex;
    justify-content: space-between;
    transition: transform 0.1s ease-in-out;
    border-radius: 5px;
    margin-bottom: 3px;

    &:hover {
      background-color: #2e426486;
      transform: scale(1.02);

      border-radius: 5px;
    }
  }
}
.bold-text {
  font-weight: bold;
}
.no-clear-button {
  margin-right: 10px;
}

@media (max-width: 999px) {
  .ShiftContainer {
    width: 300%;
    height: 150%;
  }
}
.itemDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
.history-item {
  display: flex;
  justify-content: space-between;
}

.history-item .left-column {
  flex: 1;
}

.history-item .right-column {
  flex: 1;
  text-align: left;
}
.ShiftContainer .ant-picker-calendar-date-content {
  height: 125px !important;
}
