.ant-table-tbody .row-warning {
  background-color: #fff3cc !important;
}
.ant-table-tbody > tr.row-warning:hover > td {
  background-color: #fff1a2 !important;
}
.ant-table-tbody .row-warning-on-way {
  background-color: #ccffcc !important;
}
.ant-table-tbody > tr.row-warning-on-way:hover > td {
  background-color: #abf8ab !important;
}
.ant-table-tbody .row-danger {
  background-color: #ffcccc !important;
}
.ant-table-tbody > tr.row-danger:hover > td {
  background-color: #ffaaaa !important;
}

.ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: inherit !important;
}

.ant-table-tbody > tr.row-warning > td.ant-table-column-sort {
  background-color: #fff3cc !important;
}

.ant-table-tbody > tr.row-danger > td.ant-table-column-sort {
  background-color: #ffcccc !important;
}
.saveButtonFixed {
  position: fixed;
  right: 7%;
  bottom: 5%;
  width: 100px;
  height: 40px;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.toTopButton {
  position: fixed;
  right: 4%;
  bottom: 5%;
  width: 60px;
  height: 40px;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
