.serverStatusBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;

  .process-status-indicators {
    display: flex;
    font-size: 12px;
  }
}
