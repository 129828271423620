.salesByProducts {
  display: flex;
  flex-direction: column;
  width: 1200px;
  .salesByProductsH1 {
    margin-bottom: 10px;
  }
  .salesByProductsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .inputwithrangepicker {
      width: 250px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  .datePickers {
    width: auto;
  }
  .rangePicker {
    margin-bottom: 5px;
  }
}
