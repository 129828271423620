.salesByTaxes {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  .salesByTaxesH1 {
    margin-bottom: 10px;
  }
  .salesByTaxesHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rangePicker {
      width: 250px;
    }
  }
}
