.employeeContainerLeftUpper,
.employeeContainerMidUpper,
.employeeContainerRightUpper {
  display: inline-block;
  border-radius: 10px;
  margin: 0;
  vertical-align: middle;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-right: 10px;
}

.employeeContainerRightUpper {
}

.AttendanceContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  background-color: #222b3c;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

  &.visible {
    display: flex;
    max-height: 300px;
  }

  &.hidden {
    display: none;
    max-height: 0;
  }

  .AttendanceCombinedContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 20px;
    flex-wrap: wrap;

    .form-container,
    .info-container {
      flex: 0 1 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-left: 10px;
      margin-bottom: 10px;
      background-color: #222b3c;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

      .employee-photo-container {
        display: flex;
        justify-content: center;
        height: 80px; /* Nastavte výšku kontejneru podle potřeby */
      }

      .employee-photo {
        width: 70px; /* Šířka obrázku */
        height: 70px; /* Výška obrázku */
        border-radius: 50%; /* Zaoblené rohy, aby obrázek vypadal jako kruh */
        object-fit: cover; /* Zajistí, aby byl obrázek vyplněn do rozměrů definovaných v šířce a výšce */
        cursor: pointer;
      }
      .name {
        cursor: pointer;
      }
    }
  }
}

.modalWorkDays {
  width: 1000px !important;

  .ant-modal-content {
    background-color: #2a3f55;
    gap: 50px;
    .ant-modal-title {
      background-color: #2a3f55;
      color: white;
    }
  }

  /* disable clear button from TimePicker */
  .modalWorkDaysList {
    .itemsDate {
      margin-right: 10px;
    }
    .no-clear-button {
      margin-right: 10px;
    }

    .ant-spin-container {
      .ant-list-item {
        border: 0.5px solid rgba(0, 0, 0, 0.1);
      }
    }

    .wage,
    .salary {
      margin-right: 10px;
      .text {
        color: white;
        font-weight: 700;
      }
    }

    .ant-list-item {
      border-radius: 5px;
      margin-bottom: 8px;
      padding: 10px;
      background-color: #2a3f55; // Změna pozadí pro lepší kontrast
      color: #fff; // Bílý text pro lepší čitelnost

      // Vylepšení pro zarovnání prvků uvnitř položky seznamu
      display: flex;
      align-items: center;
      justify-content: space-between;

      // Specifické úpravy pro časy a checkboxy
      .timePicker,
      .checkbox {
        margin: 0 5px;
      }

      // Vylepšení pro text a labely uvnitř položky
      span,
      .ant-checkbox-wrapper {
        color: #cad4e2; // Světlejší odstín textu pro lepší kontrast
      }
    }
  }

  // Globální úpravy pro TimePicker v rámci modálního okna
  .no-clear-button .ant-picker-clear {
    display: none; // Skrýt tlačítko pro vymazání
  }

  // Zvýraznění a úpravy pro titulky a popisky
  .modalWorkDaysListHeader {
    font-size: 16px;
    font-weight: bold;
    color: #e4e6e9; // Světlejší barva textu pro titulky
    margin-bottom: 12px; // Přidání prostoru pod titulkem
  }

  // Úpravy pro patičku modálního okna
  .modalFooter {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px;
    background-color: #1e2a3a; // Tmavší pozadí pro patičku
    border-top: 1px solid #345; // Oddělující čára pro patičku
    border-radius: 10px;
    color: white;
    .totalPaidSalary {
      margin-right: 10px;
    }
    .totalPaid {
      font-weight: bold;
      color: #a5d6a7; // Zelená pro zvýraznění celkové částky
    }
    .text {
      color: white;
      font-weight: 700;
    }
    .shift-close-button {
      margin-right: 10px;
    }
  }
}
