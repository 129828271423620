.products {
  .icon-cell.enabled {
    color: green; /* Barva ikony pro povolený stav */
    font-size: 20px;
  }

  .icon-cell.disabled {
    color: red; /* Barva ikony pro zakázaný stav */
  }
  .info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .button {
      padding: 5px;
      cursor: pointer;
      background: #19428f;
      border-radius: 12px;
      padding: 8px 20px;
      color: #ffffff;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
    }
  }

}

