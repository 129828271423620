.homeEmployeeContainer {
  width: 50vw;
  min-width: fit-content;
  margin-top: 20px;
  height: auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  user-select: none;

  .first {
    display: flex;
    flex-direction: column;
    user-select: none;
    justify-content: space-around;
    width: 700px;
    gap: 1%;
    padding: 20px;
    background-color: #222b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

    .sectionTitle {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .notesButton {
      display: flex;
      justify-content: right;
      margin-top: 10px;

      .saveButton {
        width: 100px;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .second {
    flex: 1;
    margin-top: 20px;
    padding: 20px;
    background-color: #222b3c;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    user-select: none;
    height: 200px;
    width: 700px;
    .sectionTitle {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .container {
      display: flex;
      justify-content: space-around;
      height: 100%;

      .inputSection {
        display: flex;
        flex-direction: column;

        width: 180px;
        margin-top: 10px;
        .ant-picker {
          width: 100%;
        }
      }
    }
    .productButton {
      display: flex;
      justify-content: right;
      margin-top: 10px;
      .saveButton {
        width: 100px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .third {
    display: flex;
    flex-direction: row;
    user-select: none;
    justify-content: space-around;
    width: 700px;

    .device {
      margin-top: 20px;
      width: 700px;
      border-radius: 8px;
      background-color: #222b3c;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    }
  }
}
