@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.menu,
.menuEmployee {
  img {
    width: 20px;
    height: 20px;
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 200;
      color: $soft-color;
      text-transform: uppercase;

      @include lg {
        display: none;
      }
    }

    .listItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $soft-bg;
      }
      @include sm {
        padding: 0px 0px 5px 0px;
      }
      .listItemTitle {
        @include lg {
          display: none;
        }
      }
    }
  }
}
