@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.home {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(180px, auto);
  grid-auto-flow: dense;

  @include xl {
    grid-template-columns: repeat(3, 1fr);
  }
  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }
  @include md {
    grid-template-columns: repeat(1, 1fr);
  }
  @include sm {
    grid-auto-rows: minmax(120px, auto);
  }

  .box {
    padding: 20px;
    border-radius: 10px;
    border: 2px solid $soft-bg;
  }

  .box1 {
    grid-column: span 1;
    grid-row: span 1;
    @include lg {
      order: 5;
    }
  }
  .box2 {
    grid-column: span 1;
    grid-row: span 1;
    @include lg {
      order: 5;
    }
  }
  .box3 {
    @include lg {
      order: 13;
    }
  }
  .box4 {
    grid-column: span 1;
    grid-row: span 2;
    @include sm {
      grid-row: span 1;
    }
    @include lg {
      grid-row: span 1;
      order: 11;
    }
  }
  .box7 {
    grid-column: span 2;
    grid-row: span 1;
    @include sm {
      grid-column: span 1;
    }
    @include lg {
      grid-row: span 1;
      order: 1;
    }
  }
  .box9 {
    grid-column: span 2;
    grid-row: span 1;
    @include sm {
      grid-column: span 1;
    }
    @include lg {
      order: 0;
    }
  }
  .box10 {
    grid-column: span 2;
    grid-row: span 1;

    @include sm {
      grid-column: span 1;
    }
    @include lg {
      order: 3;
    }
  }
  .box11 {
    @include lg {
      order: 13;
    }
  }
  .box12 {
    grid-column: span 2;
    grid-row: span 1;
    @include sm {
      grid-column: span 1;
    }
    @include lg {
      order: 4;
    }
  }
}

.cl-modalContent {
  font-family: "Inter", sans-serif;
}
